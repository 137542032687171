<template>
    <div class="page padd30">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="请输入员工姓名搜索" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getList" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div class="flex">
                        <div><label for=""><b>姓名：</b></label>{{item.name}}</div>
                        <!-- <div><label for="">头像：</label><img width="40px" :src="item.avatar" alt=""></div> -->
                        <div><label for=""><b>性别：</b></label>{{item.gender}}</div>
                        <div><label for=""><b>角色：</b></label>{{item.role_name}}</div>
                    </div>
                    <!-- <div>
                        <label for="">所属部门：</label>{{item.department_name}}
                    </div> -->
                    <div style="min-height:5rem;max-height:20rem;overflow:scroll;" v-if="item.range_name">
                        <label for=""><b>管理部门：</b></label>{{item.range_name}}
                    </div>
                </div>
                <div class="btn_con">
                    <a-button type="primary" size="small" ghost @click="permission(item.id,item.range)">分配权限</a-button>
                    
                </div>
            </div>
            <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
        <a-modal v-model="permissionVisible" :title="permissionTitle" @ok="permissionOk">
            <div class="role">
                <!-- <div v-for="(item,index) in roleData.role" :key="index">
                    <a-checkbox v-model="item.isChecked" @click="choose(index,item.id)"></a-checkbox>&nbsp;{{item.name}}
                </div> -->
                <div class="content_row flex" style="align-items:center;">
					<label class="info" for="">请选择角色</label>
					<div class="content_right">
						<a-select size="" v-model="roleId" placeholder="">
							<a-select-option v-for="(item,index) in roleData.role" :key="index" :value="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</div>
				</div>

                <a-tree v-model="checkedKeys" checkable :tree-data="treeData"/>
            </div>
        </a-modal>
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            key: "",
            data: [],
            
            // 成员id
            id: "",

            roleData: [],
            // 角色id
            roleId: "",
            permissionVisible: false,
            permissionTitle: "分配权限",
            // 选中的部门
            checkedKeys: [],
            treeData : [
                {
                    title: '0-0',
                    key: '0-0',
                    children: [
                        {
                            title: '0-0-0',
                            key: '0-0-0',
                            children: [
                                { title: '0-0-0-0', key: '0-0-0-0' },
                                { title: '0-0-0-1', key: '0-0-0-1' },
                                { title: '0-0-0-2', key: '0-0-0-2' },
                            ],
                        },
                    ],
                },
            ],
            // appId: "",
            // timestamp: "",
            // nonceStr: "",
            // signature: "",
            // agentid: "",
             // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({"name": this.key,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/order/orderAdd" || this.fromPath == '/scrm_wap/order/orderDetail') {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.name
        }
        this.getList();
        // 获取角色和部门
        this.getRole();
        this.getDepartment();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getList();
        },
        // 员工列表
        getList(){
            this.dataLoadStop = true;
            requestXml("/scrm/Staff/getList","POST",(res) => {
                for (let i = 0; i < res.list.length; i++) {
                    if(res.list[i].gender == 1){
                        res.list[i].gender = "男"
                    }else if(res.list[i].gender == 2){
                        res.list[i].gender = "女"
                    }else{
                        res.list[i].gender = "未定义"
                    }
                }
               this.data = res.list;
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                //     this.pagination.current += 1
                //     this.dataLoadStop = false
                // }else{
                //     this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{"name": this.key,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
            // "roleid": this.roleId_search,"department_id":this.department_id,
        },
       
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getList();
        },
        // 获取角色
        getRole(){
            requestXml("/scrm/Authority/getRoleList","GET",(res) => {
                this.roleData = res;
                this.roleId = this.roleData.role[0].id;
            })
        },
        // 获取部门
        getDepartment(){
            requestXml("/scrm/Index/getDepartment","GET",(res) => {
                console.log(res);
                this.treeData = res;
            })
        },
        // 点击分配权限
        permission(id,range){
            this.checkedKeys = [];
            this.id = id;
            if(range){
                let rangeArr = range.split(",").map(Number);
                this.checkedKeys = rangeArr;
            }
            this.permissionVisible = true;
        },
        // 设置权限
        permissionOk(){
            requestXml("/scrm/Staff/setAuthority","POST",(res) => {
                console.log(res)
                this.roleId = this.roleData.role[0].id;
                this.checkedKeys = [];
                this.permissionVisible = false;
                this.data=[];
                this.getList();
            },{"id": this.id,"roleid": this.roleId,"range": this.checkedKeys})
        }
    },
    computed: {
        
        // rowSelection() {
        //     return {
        //         onChange: (selectedRowKeys, selectedRows) => {
        //             console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //         },
        //         getCheckboxProps: record => ({
        //             props: {
        //                 disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //                 name: record.name,
        //             },
        //         }),
        //     };
        // },
    },
};
</script>
<style></style>
